<template>

  <div id="background_app">
    
    <div id="app">

      <div id="content-wrap">

          <div v-show="$root.loading"  class="page_loading">
              <loadingItem medium />

          </div>
          <div v-show="!$root.loading">
              <router-view />
          </div>
      
      </div>


      <bottomBar
       

        class="bottom-bar"
      />

    </div>

  </div>
</template>
<script>
/*

do bottomBar
 v-if="device_size_default == device_size
            && !$root.hideBottomBar "

*/
import bottomBar from "@/components/bottomBar.vue";
import loadingItem from "@/components/loadingItem.vue";

export default {
  components: {
    bottomBar,
    loadingItem,
  },
  data(){
    return{
      device_size: 0,
      device_size_default: 0,
    }
  },
  created() {
    this.device_size = window.innerWidth + window.innerHeight; 
    this.device_size_default = window.innerWidth + window.innerHeight; 

    console.log(this.$router.history.current)

  },
  /*watch: {
    device_size() {
        console.log("resize "+this.device_size)
    }
  },
  */
  beforeDestroy() { 
      window.removeEventListener('resize', this.onResize); 
      window.addEventListener("orientationchange", this.onOrientationChange);
  },
  mounted(){
     this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      window.addEventListener("orientationchange", this.onOrientationChange);
    })
  },
  methods: {

    onResize() {
      this.device_size = window.innerWidth + window.innerHeight;
    },

    onOrientationChange(){
      this.orientation = window.screen.orientation.angle

      this.isDynamicHeightOn = false; // po zmianie blokujemy dynamic Height, dzięki czemu wymiary są okej (ale podczas najbliższej zmiany nie animuje się height, ale już trudno)

    },
  }
}
</script>

<style lang="scss">
:root {
  --colorPrimary: #2a4b7c;
  --colorPrimaryDark: #213c63;
  --colorKolejneZajecia: #23497b;
  --colorJedneZajecia: #4b7dbf;
  --colorJedneZajecia2: #7ba0d1;
  --colorNavBarStart: #6f9fd8;
  --colorAlmostWhite: #F3F5FF;

  --color_background: #121212;
  --menu_bar: #181818;
  --top_gradient: #404040;
  --bottom_gradient: #282828;
  --primary_text: #ffffff;
  --secondary_text: #b3b3b3;
  --purple: #bb86fc;

  --footer_0_315: 105px;
  --footer_316_449: 120px;
  --footer_450_767: 65px;
  --footer_450_767_plus_40: 105px;

  --footer_768_949: 45px;
  --footer_950_1549: 50px;
  --footer_1550_max: 55px;

  --max_width: 500px;
}

* {
  margin: 0;
  padding: 0;
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  overflow-x: hidden;
  _overflow-y: hidden;
  margin: 0;
  box-sizing: border-box;

  max-width: var(--max_width);
  margin: auto;

  background-color: var(--menu_bar);

  
}

#background_app{
  background-color: var(--menu_bar);
}

h1, h2, h3, h4, h5, h6{
  color: var(--primary_text);
}
p, span, div{
  color: var(--secondary_text);
}

button{
    color: var(--secondary_text);
    background-color: var(--menu_bar);
    border: 1px solid var(--top_gradient);
    border-radius: 7px;
    box-shadow: 0 3px #101010;

    margin: 0 20px;
    padding: 10px;
    flex-grow: 1;
}

button:active{
    background-color: var(--color_background);
    box-shadow: 0 3px #222;
    transform: translateY(3px);
}

hr {
  height: 1px;
  border-color: var(--top_gradient);
}
/*
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
*/

/*
.content{
  margin-top: 50px;
  padding-bottom: 150px;
  
}
*/
.bottom-bar{
  max-width: var(--max_width);
}

.loading{
  padding-top: 20px;
}
.page_loading{
  padding-top: 150px;
}

// footer height
/*
@media only screen and (min-width: 1550px) {
  #content-wrap{
    padding-bottom: var(--footer_1550_max);
  }
}
@media only screen and (min-width: 950px) and (max-width: 1549px) {
  #content-wrap{
    padding-bottom: var(--footer_950_1549);
  }
}
@media only screen and (min-width: 868px) and (max-width: 949px) {
  #content-wrap{
    padding-bottom: var(--footer_768_949);
  }
}

@media only screen and (min-width: 500px) and (max-width: 867px) {
  */
  #content-wrap{
    padding-bottom: var(--footer_450_767_plus_40);
  }
  /*
}

@media only screen and (min-width: 327px) and (max-width: 499px) {
  #content-wrap{
    padding-bottom: var(--footer_316_449);
  }
}
@media only screen and (max-width: 326px) {
  #content-wrap{
    padding-bottom: var(--footer_0_315);
  }
}
*/

.naglowek{
  padding: 10px;
  
  display: flex;
  
  
}

.naglowek>h1{
    flex-grow: 5;
}

.naglowek .left, .naglowek .right{
  width: 60px;
  flex-grow: 1;
  font-size: 12px;
  margin-top: 5px;
}

.flex_container{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

input, textarea{
  width: 90%;
  font-size: 20px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 2px;
  text-align: center;
  border-radius: 7px;

  color: var(--secondary_text);
  background-color: var(--top_gradient);
  border: 1px solid var(--top_gradient);
}

.error{
  margin: 10px;
  color: red;
}

</style>
