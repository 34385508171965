<template>
  <div class="home">

    <div class="naglowek">
        <h1>Przedmioty</h1>
    </div>

    <div class="content">

        <div v-if="this.przedmioty.length==0">

          <loadingItem class="loading" medium />

        </div>

        <div v-else>

            <div  class="przedmiotyList">

              <przedmiot
                v-for="(przedmiot, index) in this.przedmioty"
                v-bind:key="index"
                :przedmiot="przedmiot.przedmiot"
                :prowadzacy="przedmiot.prowadzacy"
                :grupa="przedmiot.grupa"
              />

            </div>

        </div>


    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import przedmiot from "@/components/przedmiot.vue";
import loadingItem from "@/components/loadingItem.vue";


export default {
  name: "Przedmioty",
  components: {
    przedmiot,
    loadingItem,
  },
  data() {
    return {
      days: [],
      przedmioty: [],
     
    };
  },
  
  created() {


    // wczytanie zajec z cache
    const savedDays = this.$func.getSavedLessons();

    if(!savedDays){ // nieudalo sie wczytac
    
      axios.get("/plan2.html").then((response) => {
        let result_table = new DOMParser().parseFromString(
          response.data,
          "text/xml"
        );

        let list =
          result_table.firstChild.getElementsByTagName("tbody")[0].children;

        //console.log(list[0]);//.children[2].firstChild);

        for (let i = 0; i < list.length; ++i) {
          this.addTr(list[i]);
        }

        // sortowanie lekcji w dniu
        for (let i = 0; i < this.days.length; ++i) {
          this.days[i].lessons.sort(this.$func.sortHours);
        }
        // sortowanie dni
        this.days.sort(this.$func.sortDates);

        // zapisanie zajec do cache
        //this.$func.saveLessons({'days': this.days}); // todo todo todo todo todo todo todo todo todo todo todo todo ----- zapisywanie w cookies plan zajec
        this.przedmioty = this.$func.findPrzedmioty(this.days); 



      });

    } else{ // udalo sie wczytac
    
      this.days = savedDays;
      console.log(this.days);
      this.przedmioty = this.$func.findPrzedmioty(this.days); 
    }

    
      
    
  },
  methods: {


    addTr(tr) {
      //console.log(tr.children[3].firstChild.textContent);
      let date = tr.children[2].firstChild.textContent.split("-");
      let dateName = tr.children[3].firstChild.textContent;

      let indexOfDay = this.$func.findDay(date, this.days);

      let tmpTimeStart = tr.children[4].firstChild.textContent.split(":");
      let tmpTimeEnd = tr.children[5].firstChild.textContent.split(":");

      let przedmiot = tr.children[0].firstChild.textContent;
      let prowadzacy = tr.children[1].firstChild.textContent;
      let godzinaStart = tmpTimeStart[0];
      let minutaStart = tmpTimeStart[1];
      let godzinaKoniec = tmpTimeEnd[0];
      let minutaKoniec = tmpTimeEnd[1];
      let sala = tr.children[6].firstChild.textContent.trimStart();
      let grupa = tr.children[7].firstChild.textContent;

      let lesson = {
        przedmiot: przedmiot,
        prowadzacy: prowadzacy,
        godzinaStart: godzinaStart,
        minutaStart: minutaStart,
        godzinaKoniec: godzinaKoniec,
        minutaKoniec: minutaKoniec,
        sala: sala,
        grupa: grupa,
      };

      if (indexOfDay != -1) {
        // znaleziono juz ten dzien, dodajemy zajecia do istniejacego dnia
        this.days[indexOfDay].lessons.push(lesson);
      } else {
        // tworzymy nowy dzień i dodajemy zajęcia do nowego dnia
        let day = {
          day: date[2],
          month: date[1],
          year: date[0],
          name: this.$func.getFullDayName(dateName),
          lessons: [],
        };

        day.lessons.push(lesson);

        this.days.push(day);
      }
    },


  },
};
</script>
<style lang="scss" scoped>

.przedmiotyList{
  padding-top: 5px;
  //padding-bottom: 150px;
  
}
</style>
