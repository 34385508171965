<template>
  <div id="footer" class="fixed">
    <ul id="icons">

        <li class="tooltip" :class="{'checked': this.$route.name == 'Przedmioty', 'notchecked' : this.$route.name != 'Przedmioty'}">
            <a @click="przedmioty" >
                <i class=" fa-2x fa-solid fa-book" ></i>
            </a>
        </li>

        <li class="tooltip" :class="{'checked': this.$route.name == 'Plan', 'notchecked' : this.$route.name != 'Plan'}">
            <a @click="plan">
              <i class=" fa-2x fa-solid fa-calendar-days"></i>
            </a>
        </li>


    </ul>
  </div>
</template>

<script>
/*
<VueBottomNavigation 
    :options="options" 
    :value="selected"
    :foregroundColor="foregroundColor"
    />
*/
//import VueBottomNavigation from "bottom-navigation-vue";

export default {
  name: "bottomBar",
  props: {},
  components: {
    //VueBottomNavigation,
  },
  methods: {
    plan(){
      this.$router.push("plan")
    },
    przedmioty(){
      this.$router.push("przedmioty")
    },
    kalendarz(){
      this.$router.push("kalendarz")
    },
    notatki(){
      this.$router.push("notatki")
    }
  },
  data(){
    return{
      /*
      value: 2,
      selected: 2,
      options: [
        { id: 1, icon: "fas fa-book", title: "Przedmioty", path: { name: "Przedmioty" }},
        { id: 2, icon: "fas fa-home", title: "Lista zajęć", path: { name: "Home" } },
        { id: 3, icon: "fas fa-user", title: "Profil", path: { name: "Profil" } },

      ],
      foregroundColor: "#2a4b7c",
      */
    }
  }
};

</script>
<style lang="scss" scoped>
/*
.checked{
  display: block;
  border-radius: 0 0 10px 10px;
  top: 0px;
  position: relative;
}
*/

.disabled{
  display: none;
  top: 0;
  position: absolute; 
}

.notchecked i{
    color: var(--secondary_text)


}

.checked i{
    background-color: var(--secondary_text);
    color: var(--menu_bar);
    border: 3px solid var(--menu_bar);
}
.checked div{
    //background-color: var(--secondary_text);
}

.tooltip i{
  display: block;
  position: relative;
  border-radius: 10px;
  width: 44px;
  height: 40px;
  margin: auto;
  padding-top: 6px;

}

a{
  text-decoration: none;
}

#footer{
  border-top: 4px solid var(--top_gradient);
  background-color: var(--bottom_gradient);
  width: 100%;
  
}
.fixed{
  bottom: 0;
  position: fixed;
}

 ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
  }

  li {
    padding-top: 5px;

    list-style: none;
    display: inline-block;
    flex-grow: 1;
    height: calc(var(--footer_450_767) - 5px);
    text-align: center;
  }


/*
@media only screen and (min-width: 1550px) {
  #footer{
    height: var(--footer_1550_max);
  }
}
@media only screen and (min-width: 950px) and (max-width: 1549px) {
  #footer{
    height: var(--footer_950_1549);
  }
}
@media only screen and (min-width: 868px) and (max-width: 949px) {
  #footer{
    height: var(--footer_768_949);
  }
}
@media only screen and (min-width: 500px) and (max-width: 867px) {
*/
  #footer{
    height: var(--footer_450_767);
  }

  /*
}
@media only screen and (min-width: 327px) and (max-width: 499px) {
  #footer{
    height: var(--footer_316_449);
  }
}
@media only screen and (max-width: 326px) {
  #footer{
    height: var(--footer_0_315);
  }
}
*/
</style>
