export const func = {

  loginUser: (payload) => {
    //console.log("setcurrentuser")

    if(func.localStorageSupport()){
        //console.log("to localstorage")

        window.localStorage.setItem('JWT_TOKEN', payload.token)
        window.localStorage.setItem('USER_ID', payload.user_id)

    } else{
      //console.log("to cookies")

      let expire_days = 7;

      const d = new Date();
      d.setTime(d.getTime() + (expire_days*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      
      document.cookie = "TOKEN" + "=" + payload.token + ";" + expires + ";path=/;";// + "USER_ID" + "=" + payload.user_id + ";" + expires + ";path=/;";
      document.cookie = "USER_ID" + "=" + payload.user_id + ";" + expires + ";path=/;";

    }

 },


 logoutUser: () => {
    if(func.localStorageSupport()){
        //console.log("from localstorage")

        window.localStorage.removeItem('JWT_TOKEN');  
        window.localStorage.removeItem('USER_ID');  
      } else{
        //console.log("from cookies")

        document.cookie = "TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";// USER_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "USER_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      }
 },

 localStorageSupport: () => {
    //console.log("localstoragesupport")
    var testKey = 'test';
    try
    {
        window.localStorage.setItem(testKey, '1');
        window.localStorage.removeItem(testKey);
        return true;
    }
    catch (error)
    {
        //console.log(error)
        return false;
    }
  },


  getLoggedToken: () => {
    if(func.localStorageSupport()){
      //console.log("pobieram token z localstorage: "+window.localStorage.getItem('JWT_TOKEN'))
      return window.localStorage.getItem('JWT_TOKEN');
    } else{
      //console.log("pobieram token z cookies")

      let name = "TOKEN" + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return false;
    }

  },

  getLoggedUserId: () => {
    if(func.localStorageSupport()){
      //console.log("pobieram userId z localstorage")
      return window.localStorage.getItem('USER_ID');
    } else{
      //console.log("pobieram userId z cookies")

      let name = "USER_ID" + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
          c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
          }
      }
      return false;
    }
  },




  saveLessons: (payload) => {

    let expire_days = 150;

    const d = new Date();
    d.setTime(d.getTime() + (expire_days*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
        
    let days = JSON.stringify(payload.days);
    //console.log(days)
    //console.log((new TextEncoder().encode(days)).length)

    document.cookie = "DAYS" + "=" + days + ";" + expires + ";path=/;";
    //document.cookie = "USER_ID" + "=" + payload.user_id + ";" + expires + ";path=/;";
  },
/*
  logoutUser: () => {
    document.cookie = "TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";// USER_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "USER_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        
  },
*/
  getSavedLessons: () => {
    //console.log("pobieram userId z cookies")

    let name = "DAYS" + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
    
  },

  sortPrzedmioty: (A, B) => {
    if (A.przedmiot > B.przedmiot) return 1;
    else if (A.przedmiot == B.przedmiot) {
      if (A.prowadzacy > B.prowadzacy) return 1;
      else if (A.prowadzacy == B.prowadzacy) {
        if (A.grupa > B.grupa) return 1;
        else if (A.grupa == B.grupa) return 0;
      }
    }
    return -1;
  },

  findPrzedmioty: (days) => {
    let przedmioty = new Array();
    
    for ( let day of days ){
      for (let lesson of day.lessons){

        let przedmiot = {
          prowadzacy: lesson.prowadzacy,
          przedmiot: lesson.przedmiot,
          grupa: lesson.grupa,          
        }

        let foundPrzedmiot = false;
        for(let currentPrzedmiot of przedmioty){
          if(currentPrzedmiot.prowadzacy == przedmiot.prowadzacy && currentPrzedmiot.przedmiot == przedmiot.przedmiot && currentPrzedmiot.grupa == przedmiot.grupa)
            foundPrzedmiot = true;
        }
        if(!foundPrzedmiot)
          przedmioty.push(przedmiot);
      }
    }
    
    // sortowanie dni
    przedmioty.sort(func.sortPrzedmioty);


    //console.log(days);
    console.log(przedmioty);
    console.log("przedmioty ^")

    return przedmioty;

  },



  findDay: (date, arr) => {
    for (let i = 0; i < arr.length; ++i) {
      let day = arr[i];

      //console.log("findDay: "+i)
      //console.log(date[2])
      //console.log(day.day)

      if (date[2] == day.day && date[1] == day.month && date[0] == day.year)
        return i;
    }

    return -1;
  },

  sortDates: (A, B) => {
    if (A.year > B.year) return 1;
    else if (A.year == B.year) {
      if (A.month > B.month) return 1;
      else if (A.month == B.month) {
        if (A.day > B.day) return 1;
        else if (A.day == B.day) {
          return 0;
          // ten sam dzien wiec return 0, ale podczas dodawania nowego dnia najpierw sprawdzamy czy już ten dzień istnieje w liście, więc w praktyce nie powinna ta linijka być osiągana
        }
      }
    }
    return -1;
  },

  sortHours: (A, B) => {
    if (A.godzinaStart > B.godzinaStart) return 1;
    else if (A.godzinaStart == B.godzinaStart) {
      if (A.minutaStart > B.minutaStart) return 1;
      else if (A.minutaStart == B.minutaStart) return 0;
    }
    return -1;
  },

  sortHoursEnd: (A, B) => {
    if (A.godzinaKoniec > B.godzinaKoniec) return 1;
    else if (A.godzinaKoniec == B.godzinaKoniec) {
      if (A.minutaKoniec > B.minutaKoniec) return 1;
      else if (A.minutaKoniec == B.minutaKoniec) return 0;
    }
    return -1;
  },

  findClosestLesson: (dates) => {
    //let now = new Date(2021, 10, 10, 12, 10);
    let now = new Date();

    let todayDate = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
      godzinaKoniec: now.getHours(),
      minutaKoniec: now.getMinutes(),
    };

    let closestDay = -1;
    let closestLesson = -1;

    for (let i = 0; i < dates.length; ++i) {
      //console.log("todayDate: "+todayDate.day+"."+todayDate.month)
      //console.log("dates[i]:  "+dates[i].day+"."+dates[i].month)

      if (func.sortDates(dates[i], todayDate) > 0) {
        // dzien jest przyszły

        //console.log("sortDates(dates[i], todayDate) > 0")

        closestDay = i;
        closestLesson = 0;
        return {
          closestDay: closestDay,
          closestLesson: closestLesson,
        };
      }

      if (func.sortDates(dates[i], todayDate) == 0) {
        //console.log("sortDates(dates[i], todayDate) == 0")

        for (let j = 0; j < dates[i].lessons.length; ++j) {
          //console.log("todayDate: "+todayDate.day+"."+todayDate.month+" "+todayDate.godzinaKoniec+":"+todayDate.minutaKoniec)
          //console.log("dates[i]:  "+dates[i].day+"."+dates[i].month+" "+dates[i].lessons[j].godzinaKoniec+":"+dates[i].lessons[j].minutaKoniec)

          if (func.sortHoursEnd(dates[i].lessons[j], todayDate) >= 0) {
            closestDay = i;
            closestLesson = j;

            return {
              closestDay: closestDay,
              closestLesson: closestLesson,
            };
          }
        }
      }
    }

    return {
      closestDay: closestDay,
      closestLesson: closestLesson,
    };
  },

  getFullDayName: (short) => {
    switch (short) {
      case "pon":
        return "Poniedziałek";
      case "wt":
        return "Wtorek";
      case "śr":
        return "Środa";
      case "czw":
        return "Czwartek";
      case "pt":
        return "Piątek";
      case "sob":
        return "Sobota";
      case "niedz":
        return "Niedziela";
    }
  },

  getFullMonthName: (short) => {
    switch (short) {
      case 1:
        return "stycznia";
      case 2:
        return "lutego";
      case 3:
        return "marca";
      case 4:
        return "kwietnia";
      case 5:
        return "maja";
      case 6:
        return "czerwca";
      case 7:
        return "lipca";
      case 8:
        return "sierpnia";
      case 9:
        return "września";
      case 10:
        return "października";
      case 11:
        return "listopada";
      case 12:
        return "grudnia";
    }
  },

  getCommisionColor: (array, commision) => {
    let style={};
    style['color'] = array.get(commision)
    return style;
  },

  getPriority(priority){
    switch (priority) {
      case 0:
        return "zwykły";
      case 1:
        return "wysoki";
      case 2:
        return "bardzo wysoki";
    }
  },

  getNoteCategory(category){
    switch (category) {
      case 0:
        return "Zarząd";
      case 1:
        return "Komisja ds. Socjalnych";
      case 2:
        return "Komisja ds. Kultury";
      case 3:
        return "Komisja ds. Promocji";
      case 4:
        return "Komisja ds. Jakości Kształcenia";
      case 5:
        return "Komisja ds. Informatyzacji";
      case 6:
        return "Komisja ds. Praw Studenta";
      case 7:
        return "Sekretarz Zarządu";
      case 8:
        return "Prywatne";
    }
  },
  
  getAuthor(author){
    switch (author) {
      case 0:
        return "Kinga";
      case 1:
        return "Ania";
      case 2:
        return "Asia";
      case 3:
        return "Paulina";
      case 4:
        return "Jola";
      case 5:
        return "Wojtek";
      case 6:
        return "Ola";
      case 7:
        return "Bartek";
      case 8:
        return "Artur";
      case 9:
        return "Karol";
      case 10:
        return "Maciek";

    }
  },

  compareNotesArchive(a,b){
    if(a.year > b.year)
      return -1;
    if(a.year == b.year){
      if(a.month > b.month)
        return -1;
      if(a.month == b.month){
        if(a.day > b.day)
          return -1;
        if(a.day == b.day){
          if(a.priority > b.priority)
            return -1;
          if(a.priority == b.priority)
            return 0;
          return 1;
        }
        return 1;
      }
      return 1;
    }
    return 1;
  },

  compareNotesCurrent(a,b){

    if(a.priority > b.priority)
      return -1;
    if(a.priority == b.priority){
      if(a.year > b.year)
        return 1;
      if(a.year == b.year){
        if(a.month > b.month)
          return 1;
        if(a.month == b.month){
          if(a.day > b.day)
            return 1;
          if(a.day == b.day)
              return 0;
          
          return -1;
        }
        return -1;
      }
      return -1;
    }
    return 1;


  },

};
