import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stepOne: 1,

    closestDay: 0,
    closestLesson: 0,
    lastPrintedDay: 0,
    lastPrintedLesson: 0,

    loggedUserId: -1,
    loggedUserToken: -1,
    loggedUserCommision: -1,

    commision_colors:  new Map([
      [0, "#ccff99"],
      [1, "#a6ff4d"],
      [2, "#8cff1a"],
      [3, "#73e600"],
      [4, "#99e6ff"],
      [5, "#33ccff"],
      [6, "#0099cc"],
      [7, "#9999ff"],
      [8, "#3333ff"],
      [9, "#000099"],
      [10, "#ff9999"],
      [11, "#b30000"],
    ]),

    
    options: [ 
      {
        id: 0,
        label: 'Wszystkie wydarzenia',
        customLabel: 'Wszystkie wydarzenia',
        children: [
          {
            id: 1,
            label: 'Wydarzenia zarządowe',
            customLabel: 'Zarząd',
            children: [
              {
                id: 13,
                label: 'Przewodnicząca',
                customLabel: 'Przewodnicząca',
              },
              {
                id: 2,
                label: 'Komisja ds. Socjalnych',
                customLabel: 'Socjalna',
              },
              {
                id: 3,
                label: 'Komisja ds. Kultury',
                customLabel: 'Kultura',
              },
              {
                id: 4,
                label: 'Komisja ds. Promocji',
                customLabel: 'Promocja',
              },
              {
                id: 5,
                label: 'Komisja ds. Jakości Kształcenia',
                customLabel: 'Jakość Kształcenia',
              },
              {
                id: 6,
                label: 'Komisja ds. Informatyzacji',
                customLabel: 'Informatyzacja',
              },
              {
                id: 7,
                label: 'Komisja ds. Praw Studenta',
                customLabel: 'Prawa Studenta',
              },
              {
                id: 8,
                label: 'Sekretarz Zarządu',
                customLabel: 'Sekretarz',
              },
            ],
          },
          {
            id: 10,
            label: 'Konkretne projekty',
            customLabel: 'Projekty',
            children: [
              {
                id: 11,
                label: 'Juwenalia',
                customLabel: 'Juwenalia',
              },
              {
                id: 12,
                label: 'Akademiki',
                customLabel: 'Akademiki',
              },
            ],
          },
          {
            id: 9,
            label: 'Prywatne wydarzenia',
            customLabel: 'Prywatne',
          },
        ]
      }, 
      
    ],

    

    options_create: [ 
        {
          id: 1,
          label: 'Wydarzenia zarządowe',
          customLabel: 'Zarząd',
          children: [
            {
              id: 13,
              label: 'Przewodnicząca',
              customLabel: 'Przewodnicząca',
            },
            {
              id: 2,
              label: 'Komisja ds. Socjalnych',
              customLabel: 'Socjalna',
            },
            {
              id: 3,
              label: 'Komisja ds. Kultury',
              customLabel: 'Kultura',
            },
            {
              id: 4,
              label: 'Komisja ds. Promocji',
              customLabel: 'Promocja',
            },
            {
              id: 5,
              label: 'Komisja ds. Jakości Kształcenia',
              customLabel: 'Jakość Kształcenia',
            },
            {
              id: 6,
              label: 'Komisja ds. Informatyzacji',
              customLabel: 'Informatyzacja',
            },
            {
              id: 7,
              label: 'Komisja ds. Praw Studenta',
              customLabel: 'Prawa Studenta',
            },
            {
              id: 8,
              label: 'Sekretarz Zarządu',
              customLabel: 'Sekretarz',
            },
          ],
        },
        {
          id: 10,
          label: 'Konkretne projekty',
          customLabel: 'Projekty',
          children: [
            {
              id: 11,
              label: 'Juwenalia',
              customLabel: 'Juwenalia',
            },
            {
              id: 12,
              label: 'Akademiki',
              customLabel: 'Akademiki',
            },
          ],
        },
        {
          id: 9,
          label: 'Prywatne wydarzenia',
          customLabel: 'Prywatne',
        },
      
    ],

    options_notatki: [ 
      {
        id: 0,
        label: 'Wszystkie notatki',
        customLabel: 'Wszystkie',
        children: [
          {
            id: 1,
            label: 'Notatki zarządowe',
            customLabel: 'Zarząd',
            children: [
              {
                id: 13,
                label: 'Przewodnicząca',
                customLabel: 'Przewodnicząca',
              },
              {
                id: 2,
                label: 'Komisja ds. Socjalnych',
                customLabel: 'Socjalna',
              },
              {
                id: 3,
                label: 'Komisja ds. Kultury',
                customLabel: 'Kultura',
              },
              {
                id: 4,
                label: 'Komisja ds. Promocji',
                customLabel: 'Promocja',
              },
              {
                id: 5,
                label: 'Komisja ds. Jakości Kształcenia',
                customLabel: 'Jakość Kształcenia',
              },
              {
                id: 6,
                label: 'Komisja ds. Informatyzacji',
                customLabel: 'Informatyzacja',
              },
              {
                id: 7,
                label: 'Komisja ds. Praw Studenta',
                customLabel: 'Prawa Studenta',
              },
              {
                id: 8,
                label: 'Sekretarz Zarządu',
                customLabel: 'Sekretarz',
              },
            ],
          },
          {
            id: 10,
            label: 'Konkretne projekty',
            customLabel: 'Projekty',
            children: [
              {
                id: 11,
                label: 'Juwenalia',
                customLabel: 'Juwenalia',
              },
              {
                id: 12,
                label: 'Akademiki',
                customLabel: 'Akademiki',
              },
            ],
          },
          {
            id: 9,
            label: 'Prywatne notatki',
            customLabel: 'Prywatne',
          },
        ]
      }, 
      
    ],

    options_priority: [ 
      {
        id: 0,
        label: 'Normalny',
        customLabel: 'Normalny',
      }, 
      {
        id: 1,
        label: 'Wysoki',
        customLabel: 'Wysoki',
      }, 
      {
        id: 2,
        label: 'Bardzo wysoki',
        customLabel: 'Bardzo wysoki',
      }, 
    ],

  },
  mutations: {
    stepOneInc: (state) => {
      state.stepOne++;
    },
  },
  actions: {},
  modules: {},
});
