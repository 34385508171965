import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { func } from "./functions.js";
import VCalendar from 'v-calendar';

Vue.prototype.$func = func;
Vue.config.productionTip = false;
Vue.use(VCalendar, {})

const app = new Vue({
  data: { 
    loading: false,
    hideBottomBar: true,
  },
  router,
  store,
  render: (h) => h(App),
})


router.beforeEach((to, from, next) => {
  //console.log("beforeach")
  app.loading = true
  next();
})
router.afterEach((to) => {
  //console.log("aafter")
  if(to.name == "Login" || to.name == "LoginPassword")
    app.hideBottomBar = true;
  

  if(to.name != "Plan")
    setTimeout(() => {
      app.loading = false
      if(to.name != "Login" && to.name != "LoginPassword")
        app.hideBottomBar = false;

    }, 500) // timeout for demo purposes


})

app.$mount("#app");