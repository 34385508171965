import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
//import formPage from "../views/formPage.vue";
import Przedmioty from "../views/Przedmioty.vue";
//import Notatki from "../views/Notatki.vue";
const Home = () => import('../views/Home.vue');
const Notatki = () => import('../views/Notatki.vue');
const Kalendarz = () => import('../views/Kalendarz.vue');
//const Login = () => import('../views/Login.vue');
const LoginPassword = () => import('../views/LoginPassword.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    //name: "Login",
    //component: Login,
    name: "Plan",
    component: Home,
  },
  {
    path: "/pass",
    name: "LoginPassword",
    component: LoginPassword,
    props: true,
  },
  {
    path: "/przedmioty",
    name: "Przedmioty",
    component: Przedmioty,
  },
  {
    path: "/notatki",
    name: "Notatki",
    component: Notatki,
    //component: () => import(/* webpackChunkName: 'Notatki' */ '../views/Notatki.vue'),

  },
  {
    path: "/Kalendarz",
    name: "Kalendarz",
    component: Kalendarz,
    //component: () => import(/* webpackChunkName: 'Kalendarz' */ '../views/Kalendarz.vue'),

  },
  {
    path: "/plan",
    name: "Plan",
    component: Home,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
