<template>
  <div class="przedmiot">
    <div class="describe">
      <div class="nazwa">{{ this.przedmiot }}</div>
      <div class="grupa">prowadzący: {{ this.prowadzacy }}</div>
    </div>
    <div class="arrow">

    </div>
  </div>
</template>

<script>

export default {
  name: "przedmiot",
  props: {
    przedmiot: String,
    prowadzacy: String,
    grupa: String,
  },
  components: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
* {
  font-family: sans-serif;
}

.przedmiot{
    margin: 10px;

    padding: 10px 20px;

    background-color: var(--color_background);
}

.describe {
  //width: 70%;


  //font-family: sans-serif;
  //font-weight: bold;
  color: white;
  font-size: 18px;
  text-align: left;
  
    //display: inline-block;
   // float: left;

}
.arrow{
 // display: inline-block;
  //    float: right;

}
.grupa{
  font-size: 15px;
}

.date_name {
  float: left;
}

.date {
  float: right;
}
</style>
